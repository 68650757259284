import React from "react";
import styles from "./catalogo-thumbnail.module.scss";
import DownloadIcon from "@assets/icons/pdf.svg";
import { Text } from "../../atoms";

interface CatalogoThumbnailProps {
  titulo: string;
  fecha: string;
  imagen: string;
  adjunto: string;
}

export const CatalogoThumbnail = (props: CatalogoThumbnailProps) => {
  const { titulo, imagen, adjunto, fecha } = props;
  return (
    <a href={adjunto} target="_blank" rel="noopener noreferrer">
      <div
        style={{ backgroundImage: `url(${imagen})` }}
        className={styles.root}
      >
        <div className={styles.card}>
          <Text as="h4">{titulo}</Text>
          <Text className={styles.date}>{fecha}</Text>
          <div className={styles.link}>
            <Text as="span">Ver catálogo</Text>
            <DownloadIcon />
          </div>
        </div>
      </div>
    </a>
  );
};
