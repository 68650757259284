import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import styles from "./productos-sub-menu.module.scss";
import { useProductos } from "src/context/useProductos";
import { useFamilias } from "src/context/useFamilias";
import { ProductCard } from "../..";
import Link from "next/link";
import { cleanUrl } from "@utils/url-cleaner";
import { MainRouter } from "@config/urls";
import { TemplateText } from "src/presentation/components/atoms";
import { useProductosDestacados } from "src/context/useProductosDestacados";
import { settings } from "@config/settings";
import Cookies from "js-cookie";
import useCombinedProducts from "src/context/useCombinedProducts";

export const ProductosSubMenu = (props: any) => {
  const { visible, country } = props;
  const [isVisible, setIsVisible] = useState(visible);
  const { t } = useTranslation();
  const familiesData = useFamilias();
  const productos = useProductosDestacados();
  const { productosFinal } = useCombinedProducts();
  const [pais, setPais] = useState(settings.DEFAULT_COUNTRY);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);
  const cookie = Cookies.get(settings.countryCookie);
  useEffect(() => {
    if (cookie) setPais(cookie);
  }, [cookie, productos]);

  const handleDismiss = () => {
    setIsVisible(false);
  };
  return (
    <div
      className={classNames(styles.root, {
        [styles.show]: isVisible,
        [styles.hide]: !isVisible,
      })}
    >
      <div className={styles.left}>
        {productosFinal &&
          productosFinal.slice(0, 3).map((item: any) => {
            return (
              <div key={item.id} className={styles.wrapper}>
                <ProductCard
                  internacional={item.internacional}
                  id={item.id}
                  slug={item.slug}
                  name={item.nombre}
                  eco={item.ecologico}
                  category={
                    familiesData.find((x) => x.id === item.familia)?.nombre
                  }
                  categoryColor={
                    familiesData.find((x) => x.id === item.familia)?.color
                  }
                  image={item.imagen_catalogo}
                  descripcion={[item.descripcion2]}
                  country={country}
                  animated
                  padding={0}
                />
              </div>
            );
          })}
      </div>
      <div className={styles.divider} />
      <div className={styles.right}>
        <div className={styles.familiesContainer}>
          {familiesData &&
            familiesData.map((item) => {
              return (
                <Link
                  legacyBehavior
                  href={{
                    pathname: `/${pais}${MainRouter.productos.generatePath()}`,
                    query: { familia: `${cleanUrl(item.nombre)}` },
                  }}
                  key={item.id}
                >
                  <a className={styles.family} onClick={handleDismiss}>
                    <TemplateText
                      template="small"
                      style={{
                        borderLeft: `4px solid ${item.color}`,
                      }}
                    >
                      {item.nombre}
                    </TemplateText>
                  </a>
                </Link>
              );
            })}
        </div>
        <Link
          legacyBehavior
          href={{
            pathname: `/${pais}${MainRouter.productos.generatePath()}`,
            query: { familia: null },
          }}
        >
          <a className={styles.todosLink} onClick={handleDismiss}>
            <TemplateText template="small">
              {t("ProductosSubMenu.todosLabel")}
            </TemplateText>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(ProductosSubMenu);
