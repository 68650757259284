import { Serializable } from "@interfaces/serializable";

export interface ProductoSitemapDTO {
  internacional: boolean;
  slug: string;
  pais: string;
  id_producto_generico: number;
}

export class ProductoSitemap implements Serializable<ProductoSitemap> {
  internacional: boolean;
  slug: string;
  pais: string;
  id_producto_generico: number;

  deserialize(input: ProductoSitemapDTO): ProductoSitemap {
    this.slug = input.slug;
    this.internacional = input.internacional;
    this.pais = input.pais || "ES";
    this.id_producto_generico = input.id_producto_generico;

    return this;
  }
  deserializeArray(input: ProductoSitemapDTO[]): ProductoSitemap[] {
    return input.map((input) => new ProductoSitemap().deserialize(input));
  }
}
