import { settings } from "@config/settings";
import { Producto } from "@models/producto";
import { useAppContext } from "@pages/_app";
import { Logger } from "@services/logger";
import { ProductosService } from "@services/productos-service";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useProductosGenericos = () => {
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;
  const { lang } = router.query;
  const [productos, setProductos] = useState(
    ctx.productosGenericosData ? JSON.parse(ctx.productosGenericosData) : []
  );

  useEffect(() => {
    if (ctx.productosGenericosData)
      return setProductos(JSON.parse(ctx.productosGenericosData));

    new ProductosService()
      .getProductosGenericos({
        locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
      })
      .then((response) => {
        Logger.log("Setting useProductosGenericos, from client side");
        setProductos(response);
      });
  }, [ctx.country, ctx.productosGenericosData, locale, lang, ctx]);

  return productos;
};
