import { endpoints, settings } from "@config/settings";
import { Producto, ProductoDTO } from "@models/producto";
import { ProductoDetalle, ProductoDetalleDTO } from "@models/producto-detalle";

import { HttpService } from "./http-service";
import { MOCKTEST } from "./mock/responsegenericoMock";
import {
  ProductoGenerico,
  ProductoGenericoDTO,
} from "@models/productoGenericos";
import {
  ProductoDetalleGenerico,
  ProductoDetalleGenericoGenericoDTO,
} from "@models/producto-detalle-generico";
import { ProductoSitemap, ProductoSitemapDTO } from "@models/producto-sitemap";

interface getProductosOpts {
  locale?: string;
  country?: string;
}

interface getProductDetailOpts {
  locale?: string;
  country?: string;
  slug: string;
}

export class ProductosService {
  private http = HttpService;

  async getProductos(opts: getProductosOpts): Promise<Producto[]> {
    const params = {
      lang: opts.locale,
      empresa: settings.BIOVERT_EMPRESA,
      pais: opts.country,
    };
    const url = `${settings.API_BASE_URL}${
      endpoints.back.productos
    }/?nocache=${new Date(Date.now())}`;
    const response = await this.http.get<{ datos: ProductoDTO[] }>(url, {
      params,
    });

    const results = new Producto().deserializeArray(response.data.datos);
    return results;
  }

  async getProductosGenericos(
    opts: getProductosOpts
  ): Promise<ProductoGenerico[]> {
    const params = {
      lang: opts.locale,
    };
    const url = `${settings.API_BASE_URL}${
      endpoints.back.productos_genericos
    }/?nocache=${new Date(Date.now())}`;
    const response = await this.http.get<{ datos: ProductoGenericoDTO[] }>(
      url,
      {
        params,
      }
    );

    const results = new ProductoGenerico().deserializeArray(
      response.data.datos
    );

    return results;
  }

  async getProductosDestacados(opts: getProductosOpts): Promise<Producto[]> {
    const params = {
      lang: opts.locale,
      empresa: settings.BIOVERT_EMPRESA,
      pais: opts.country,
      limite: 3,
    };
    const url = `${settings.API_BASE_URL}${
      endpoints.back.productos
    }/?nocache=${new Date(Date.now())}`;
    const response = await this.http.get<{ datos: ProductoDTO[] }>(url, {
      params,
    });

    const results = new Producto().deserializeArray(response.data.datos);
    return results;
  }

  async getProductoDetalle(
    opts: getProductDetailOpts
  ): Promise<ProductoDetalle> {
    const params = {
      lang: opts.locale,
      empresa: settings.BIOVERT_EMPRESA,
      pais: opts.country,
    };
    const url = `${settings.API_BASE_URL}${endpoints.back.producto_detalle}${opts.slug}`;

    const response = await this.http.get<ProductoDetalleDTO>(url, {
      params,
    });
    const result = new ProductoDetalle().deserialize(response.data);
    return result;
  }

  async getProductoDetalleGenerico(
    opts: getProductDetailOpts
  ): Promise<ProductoDetalleGenerico> {
    const params = {
      lang: opts.locale,
    };
    const url = `${settings.API_BASE_URL}${endpoints.back.producto_detalle_generico}${opts.slug}`;

    const response = await this.http.get<ProductoDetalleGenericoGenericoDTO>(
      url,
      {
        params,
      }
    );
    const result = new ProductoDetalleGenerico().deserialize(response.data);
    return result;
  }

  async getProductoRedes(opts: getProductDetailOpts): Promise<any> {
    const params = {
      lang: opts.locale,
      pais: opts.country,
      empresa: settings.BIOVERT_EMPRESA,
    };
    //cambiar
    const url = `${settings.API_BASE_URL}${endpoints.back.prodRedes}?id=${
      opts.slug
    }&pais=${params.pais}&empresa=${params.empresa}&nocache=${new Date(
      Date.now()
    )}`;
    try {
      const response = await this.http.get<any>(url);
      return response.data;
    } catch (err: any) {
      console.log("ERROR: ", err);
    }
  }

  async getAllProductos(): Promise<ProductoSitemap[]> {
    const params = {
      empresa: settings.BIOVERT_EMPRESA,
    };
    const url = `${settings.API_BASE_URL}${endpoints.back.allproducts}`;
    const response = await this.http.get<ProductoSitemapDTO[]>(url, {
      params,
    });
    const results = new ProductoSitemap().deserializeArray(response.data);
    return results;
  }
}
