import { Serializable } from "@interfaces/serializable";

export interface ProductoGenericoDTO {
  slug: string;
  id: number;
  nombre: string;
  descripcion1: string;
  descripcion2: string;
  imagen_catalogo: string;
  familia: number;
  ecologico: boolean;
  prioridad: number;
  grupos_de_cultivo: Array<number>;
}

export class ProductoGenerico implements Serializable<ProductoGenerico> {
  slug: string;
  id: number;
  nombre: string;
  descripcion1: string;
  descripcion2: string;
  imagen_catalogo: string;
  familia: number;
  ecologico: boolean;
  prioridad: number;
  grupos_de_cultivo: Array<number>;
  internacional: boolean;

  deserialize(input: ProductoGenericoDTO): ProductoGenerico {
    this.slug = input.slug || "";
    this.id = input.id || 0;
    this.nombre = input.nombre || "";
    this.descripcion1 = input.descripcion1 || "";
    this.descripcion2 = input.descripcion2 || "";
    this.imagen_catalogo = input.imagen_catalogo || "";
    this.familia = input.familia || 0;
    this.ecologico = input.ecologico || false;
    this.prioridad = input.prioridad || 0;
    this.grupos_de_cultivo = input.grupos_de_cultivo || [];
    this.internacional = true;
    return this;
  }

  deserializeArray(input: ProductoGenericoDTO[]): ProductoGenerico[] {
    return input.map((input) => new ProductoGenerico().deserialize(input));
  }
}
