import { ManvertMedio } from "@models/manvert-medio";
import React from "react";
import { MedioThumbnail } from "..";
import styles from "./manvert-medios-grid.module.scss";

interface ManvertMediosGridProps {
  mediosData: Array<ManvertMedio>;
}

export const ManvertMediosGrid = (
  props: ManvertMediosGridProps
): JSX.Element => {
  const { mediosData } = props;

  return (
    <div className={styles.root}>
      {mediosData &&
        mediosData.map((medio) => {
          return (
            <MedioThumbnail
              key={medio.id}
              titulo={medio.titulo}
              fecha={medio.fecha}
              imagen={medio.imagen.url}
              link={medio.link}
            />
          );
        })}
    </div>
  );
};
