import React from "react";
import { useTranslation } from "next-i18next";
import styles from "./croptology-link.module.scss";
import FreeIcon from "@assets/icons/free.svg";
import { MainRouter } from "@config/urls";
import { SimpleLink, TemplateText, Text } from "../../atoms";
import { useRouter } from "next/router";
import { AnalitycsService } from "@services/analytics-service";
import Link from "next/link";

export const CroptologyLink = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const handleClick = () => {
    new AnalitycsService().trackEvent({
      category: "Newsletter",
      action: "SolicitarRegistro",
    });
  };
  return (
    <div className={styles.root}>
      <Link legacyBehavior href={MainRouter.croptology.generatePath()}>
        <a onClick={handleClick}>
          <div className={styles.info}>
            <FreeIcon />
            <Text as="h3" className={styles.manvert}>
              manvert
            </Text>
            <Text as="h3" className={styles.news}>
              croptology
            </Text>
            <TemplateText template="small">
              {t("CroptologyLink.description")}
            </TemplateText>
          </div>
          <div className={styles.linkwrapper}>
            <SimpleLink
              simbolyc
              strong
              link={MainRouter.newsletter.generatePath()}
              label={t("CroptologyLink.buttonlink")}
            />
          </div>
        </a>
      </Link>
    </div>
  );
};
