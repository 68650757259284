import { Catalogo } from "@models/catalogo";
import React from "react";
import { CatalogoThumbnail } from "..";
import styles from "./catalogos-grid.module.scss";

interface CatalogosGridProps {
  catalogosData: Array<Catalogo>;
}

export const CatalogosGrid = (props: CatalogosGridProps): JSX.Element => {
  const { catalogosData } = props;
  return (
    <div className={styles.root}>
      {catalogosData &&
        catalogosData.map((item) => {
          return (
            <CatalogoThumbnail
              key={item.id}
              titulo={item.titulo}
              imagen={item.imagen.url}
              adjunto={item.archivo_adjunto.url}
              fecha={item.fecha}
            />
          );
        })}
    </div>
  );
};
