import React, { useState, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";

import LogoManvertIcon from "@assets/icons/manvert-logo-black.svg";
import BurgerIcon from "@assets/icons/burgermenu.svg";
import CloseIcon from "@assets/icons/close.svg";
import InicioIcon from "@assets/icons/favicon.svg";
import styles from "./navbar.module.scss";
import Link from "next/link";
import { MainRouter } from "@config/urls";
import { Breadcrumb, LanguageDropdown } from "..";
import { DiagonalDivider, Image, TemplateText } from "../../atoms";
import { NosotrosSubMenu } from "./nosotros-sub-menu/nosotros-sub-menu";
import { BreadcrumbItem } from "@interfaces/breadcrumb-item";
import { ProductosSubMenu } from "./productos-sub-menu/productos-sub-menu";
import { AnalitycsService } from "@services/analytics-service";
import Cookies from "js-cookie";
import { settings } from "@config/settings";

interface NavbarProps {
  breadcrumb?: BreadcrumbItem[];
}

export const Navbar = (props: NavbarProps): JSX.Element => {
  const { breadcrumb = [] } = props;
  const MOBILE_THRESHOLD = 1023;
  const { t } = useTranslation();
  const [subMenu, setSubMenu] = useState<any>(null);
  const [scrollMenu, setScrollMenu] = useState({
    visible: true,
    prevPosition: 0,
  });
  const [currentDimensions, setCurrentDimensions] = useState({
    h: 0,
    w: 0,
  });

  const [scrollY, setScrollY] = useState(0);
  const [pais, setPais] = useState(settings.DEFAULT_COUNTRY);
  function logit() {
    setScrollY(window.pageYOffset);
  }
  const cookie = Cookies.get(settings.countryCookie);
  useEffect(() => {
    if (cookie) setPais(cookie);
  }, [cookie]);

  useEffect(() => {
    window.addEventListener("scroll", logit);

    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);

  useEffect(() => {
    setScrollMenu({
      visible: true,
      prevPosition: window.pageYOffset,
    });
    setCurrentDimensions({
      h: window.innerHeight,
      w: window.innerWidth,
    });
  }, []);

  const hideAllMenus = () => {
    setSubMenu(null);
  };

  const handleMobileLink = () => {
    document.body.style.position = "static";
    setSubMenu(null);
  };

  const handleToggleMobilemenu = () => {
    if (subMenu === "mobile") {
      //document.body.style.position = "static";
      setSubMenu(null);
    } else {
      //document.body.style.position = "static";
      setSubMenu("mobile");
    }
  };

  const getNavPosition = () => {
    if (!scrollMenu.visible && subMenu === null) return { top: "-150px" };
    return { top: "0px" };
  };

  useEffect(() => {
    const update = () => {
      setCurrentDimensions({
        h: window.innerHeight,
        w: window.innerWidth,
      });
      if (currentDimensions.w > MOBILE_THRESHOLD) {
        document.body.style.position = "static";
        hideAllMenus();
      }
    };
    window.addEventListener("resize", update);
    return () => {
      document.body.style.position = "static";
      window.removeEventListener("resize", update);
    };
  }, [currentDimensions.w]);

  useEffect(() => {
    const handleScroll = () => {
      const prevScrollpos = scrollMenu.prevPosition;
      const currentScrollPos = window.pageYOffset;
      const visible =
        prevScrollpos > currentScrollPos || currentScrollPos < 100;

      setScrollMenu({
        prevPosition: currentScrollPos,
        visible,
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollMenu.prevPosition]);

  return (
    <div
      style={getNavPosition()}
      onMouseLeave={hideAllMenus}
      className={styles.root}
    >
      <div className={styles.bar}>
        <Link href={MainRouter.home.generatePath()} className={styles.banner}>
          <Image
            src="/static/images/manvert-logo-black.svg"
            alt="Manvert Logo Banner"
          />
        </Link>
        {currentDimensions.w > MOBILE_THRESHOLD ? (
          <div className={styles.navData}>
            <div className={styles.topRow}>
              <Link
                onClick={() => {
                  new AnalitycsService().trackEvent({
                    category: "Contacto",
                    action: "AccederContactanos",
                  });
                }}
                onMouseOver={() => setSubMenu(null)}
                href={MainRouter.contactanos.generatePath()}
              >
                <TemplateText template="micro">
                  {t("topNavigationBar.contactUs")}
                </TemplateText>
              </Link>
              {/* <LanguageDropdown /> */}
            </div>
            <div className={styles.links}>
              <Link
                href={{
                  pathname: `/${pais}${MainRouter.productos.generatePath()}`,
                }}
                onMouseOver={() => setSubMenu("productos")}
              >
                {t("topNavigationBar.links.productos")}
              </Link>
              <DiagonalDivider />

              <Link
                href={{
                  pathname: `/${pais}${MainRouter.soluciones.generatePath()}`,
                }}
                onMouseOver={() => setSubMenu(null)}
              >
                {t("topNavigationBar.links.soluciones")}
              </Link>
              <DiagonalDivider />
              <Link
                onMouseOver={() => setSubMenu(null)}
                href={{
                  pathname: `/${pais}${MainRouter.ensayos.generatePath()}`,
                }}
              >
                {t("topNavigationBar.links.ensayos")}
              </Link>
              <DiagonalDivider />
              <Link
                href={MainRouter.nosotros.generatePath()}
                onMouseOver={() => setSubMenu("nosotros")}
              >
                {t("topNavigationBar.links.nosotros")}
              </Link>
              <DiagonalDivider />

              <Link
                onMouseOver={() => setSubMenu(null)}
                href={MainRouter.medios.generatePath()}
              >
                {t("topNavigationBar.links.medios")}
              </Link>
              <DiagonalDivider />
              <Link
                href={MainRouter["hazte-distribuidor"].generatePath()}
                onMouseOver={() => setSubMenu(null)}
              >
                {t("topNavigationBar.links.distribuidor")}
              </Link>
            </div>
          </div>
        ) : (
          <div className={styles.mobileNavData}>
            <button
              onClick={handleToggleMobilemenu}
              type="button"
              className={styles.burgerButton}
            >
              <BurgerIcon />
            </button>
          </div>
        )}
      </div>
      {currentDimensions.w > MOBILE_THRESHOLD ? (
        <Breadcrumb values={breadcrumb} />
      ) : null}
      <ProductosSubMenu country={pais} visible={subMenu === "productos"} />
      <NosotrosSubMenu visible={subMenu === "nosotros"} />
      <div
        className={classNames(styles.mobileMenu, {
          [styles.show]: subMenu === "mobile",
          [styles.hide]: subMenu !== "mobile",
        })}
      >
        {/* <div className={styles.languages}>
          <LanguageDropdown />
        </div> */}
        <Link href={MainRouter.home.generatePath()}>
          <InicioIcon />
        </Link>
        <Link
          onClick={handleMobileLink}
          href={{
            pathname: `/${pais}${MainRouter.productos.generatePath()}`,
          }}
        >
          {t("topNavigationBar.links.productos")}
        </Link>
        <Link
          href={{
            pathname: `/${pais}${MainRouter.soluciones.generatePath()}`,
          }}
          onClick={handleMobileLink}
        >
          {t("topNavigationBar.links.soluciones")}
        </Link>
        <Link
          href={{
            pathname: `/${pais}${MainRouter.ensayos.generatePath()}`,
          }}
          onClick={handleMobileLink}
        >
          {t("topNavigationBar.links.ensayos")}
        </Link>
        <Link
          onClick={handleMobileLink}
          href={MainRouter.nosotros.generatePath()}
        >
          {t("topNavigationBar.links.nosotros")}
        </Link>
        <Link
          onClick={handleMobileLink}
          href={MainRouter.medios.generatePath()}
        >
          {t("topNavigationBar.links.medios")}
        </Link>
        <Link
          onClick={handleMobileLink}
          href={MainRouter["hazte-distribuidor"].generatePath()}
        >
          {t("topNavigationBar.links.distribuidor")}
        </Link>
        <Link
          onClick={handleMobileLink}
          href={MainRouter.contactanos.generatePath()}
        >
          {t("topNavigationBar.contactUs")}
        </Link>
        <button
          onClick={handleToggleMobilemenu}
          type="button"
          className={styles.closebutton}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};
