import { settings } from "@config/settings";
import { Cultivo } from "@models/cultivos";
import { useAppContext } from "@pages/_app";
import { CultivosService } from "@services/cultivos-service";
import { Logger } from "@services/logger";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useCultivos = (): Cultivo[] => {
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;

  const [cultivos, setCultivos] = useState(
    new Cultivo().deserializeArray(
      ctx.cultivosData ? JSON.parse(ctx.cultivosData) : []
    )
  );

  useEffect(() => {
    if (ctx.cultivosData) return;

    new CultivosService()
      .getCultivos({
        country: ctx.country,
        locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
      })
      .then((response) => {
        Logger.log("Setting cultivos, from client side");
        setCultivos(response);
      });
  }, [ctx.country, ctx.cultivosData, locale]);

  return cultivos;
};
