import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";
import cx from "classnames";
import styles from "./noticia-thumbnail.module.scss";
import { MainRouter } from "@config/urls";
import { Image, Text } from "../../atoms";

interface NoticiaThumbnailProps {
  title: string;
  image: string;
  date: string;
  slug: string;
}

export const NoticiaThumbnail = (props: NoticiaThumbnailProps) => {
  const { title, image, date, slug } = props;
  const { t } = useTranslation();

  return (
    <Link legacyBehavior passHref href={`${MainRouter.medios.generatePath()}/${slug}`}>
      <div className={styles.root}>
        <Image
          className={styles.bgimage}
          src={image}
          loading="lazy"
          alt={title}
        />
        <div className={styles.card}>
          <Text as="h4">
            {title.length > 70 ? title.substring(0, 70) + "..." : title}
          </Text>
          <Text className={cx(styles.date)}>{date}</Text>
          <Text className={styles.link}>{t("NoticiaThumbnail.link")}</Text>
        </div>
      </div>
    </Link>
  );
};
