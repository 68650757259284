type Paths =
  | "home"
  | "cookies"
  | "quienes-somos"
  | "equipo"
  | "innovacion"
  | "biovert-mundo"
  | "croptology"
  | "calidad"
  | "compromiso"
  | "legal"
  | "productos"
  | "producto-detalle"
  | "soluciones"
  | "ensayos"
  | "ensayos-detalle"
  | "redes"
  | "nosotros"
  | "privacidad"
  | "privacidadEn"
  | "newsletter"
  | "medios"
  | "contactanos"
  | "hazte-distribuidor"
  | "producto-no-encontrado"
  | "producto-detalle-internacional"
  | "canal-etico"
  | "canal-etico-en";

type RouterType = {
  [K in Paths]: {
    generatePath(keys?: any): string;
  };
};

const MainRouter: RouterType = {
  home: { generatePath: () => "/" },
  productos: { generatePath: () => "/productos" },
  nosotros: { generatePath: () => "/nosotros" },
  "producto-detalle": {
    generatePath: (keys?: any) => `${keys.country}/productos/${keys.id}`,
  },
  "producto-detalle-internacional": {
    generatePath: (keys?: any) =>
      `${keys.country}/productos/internacional/${keys.id}`,
  },
  soluciones: { generatePath: () => "/soluciones" },
  ensayos: { generatePath: () => "/ensayos" },
  "ensayos-detalle": {
    generatePath: (keys?: any) => `${keys.country}/ensayos/${keys.id}`,
  },
  medios: { generatePath: () => "/medios" },
  contactanos: { generatePath: () => "/contactanos" },
  "hazte-distribuidor": { generatePath: () => "/hazte-distribuidor" },
  "biovert-mundo": { generatePath: () => "/nosotros/manvert-en-el-mundo" },
  croptology: { generatePath: () => "/nosotros/croptology" },
  equipo: { generatePath: () => "/nosotros/equipo-y-valores" },
  "quienes-somos": { generatePath: () => "/nosotros/quienes-somos" },
  compromiso: { generatePath: () => "/nosotros/compromiso-manvert" },
  calidad: { generatePath: () => "/nosotros/calidad-y-medioambiente" },
  innovacion: { generatePath: () => "/nosotros/innovacion" },
  privacidad: { generatePath: () => "/politica-de-privacidad" },
  privacidadEn: { generatePath: () => "en/politica-de-privacidad" },
  "canal-etico": { generatePath: () => "/canal-etico" },
  "canal-etico-en": { generatePath: () => "/en/canal-etico" },

  redes: { generatePath: () => "/politica-de-redes-sociales" },
  legal: { generatePath: () => "/legal" },
  cookies: { generatePath: () => "/cookies" },
  newsletter: { generatePath: () => "/newsletter" },
  "producto-no-encontrado": { generatePath: () => "/producto-no-encontrado" },
};

export { MainRouter };
