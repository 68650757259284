/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import cx from "classnames";
import styles from "./toggle.module.scss";

export const Toggle = (props: any) => {
  const {
    variant = "round",
    color = "primary",
    checked = false,
    onChange,
    disabled = false,
  } = props;

  return (
    <label
      className={cx(styles.switch, styles[`col-${color}`], {
        [styles.disabled]: disabled,
      })}
    >
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span
        className={cx(styles.slider, { [styles.round]: variant === "round" })}
      />
    </label>
  );
};
