import { Serializable } from "@interfaces/serializable";

export interface ProductoDTO {
  slug: string;
  id: number;
  nombre: string;
  descripcion1: string;
  descripcion2: string;
  producto_generico: number;
  imagen_catalogo: string;
  familia: number;
  ecologico: boolean;
  internacional: boolean;
  prioridad: number;
  grupos_de_cultivo: Array<number>;
}

export class Producto implements Serializable<Producto> {
  slug: string;
  id: number;
  nombre: string;
  descripcion1: string;
  descripcion2: string;
  producto_generico: number;
  imagen_catalogo: string;
  familia: number;
  internacional: boolean;
  ecologico: boolean;
  prioridad: number;
  grupos_de_cultivo: Array<number>;

  deserialize(input: ProductoDTO): Producto {
    this.slug = input.slug || "";
    this.id = input.id || 0;
    this.nombre = input.nombre || "";
    this.descripcion1 = input.descripcion1 || "";
    this.descripcion2 = input.descripcion2 || "";
    this.producto_generico = input.producto_generico || 0;
    this.imagen_catalogo = input.imagen_catalogo || "";
    this.familia = input.familia || 0;
    this.ecologico = input.ecologico || false;
    this.prioridad = input.prioridad || 0;
    this.internacional = input.internacional || false;

    this.grupos_de_cultivo = input.grupos_de_cultivo || [];
    return this;
  }
  deserializeArray(input: ProductoDTO[]): Producto[] {
    return input.map((input) => new Producto().deserialize(input));
  }
}
