import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";

import styles from "./tecnologia-manvert-widget.module.scss";
import { useProductos } from "src/context/useProductos";
import { Image, TemplateText, Text } from "../../atoms";
import { useRouter } from "next/router";
import { settings } from "@config/settings";
import { ProductCard } from "..";
import { useFamilias } from "src/context/useFamilias";
import { useProductosGenericos } from "src/context/useProductosGenericos";
import useFilteredProducts from "src/context/useFilteredProducts";

export const TecnologiasManvertWidget = (props: any): JSX.Element => {
  const { country } = props;
  const [activeTab, setActiveTab] = useState(0);
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { locale } = router;
  const productos = useProductos();
  const familiesData = useFamilias();
  const productosGenericos = useProductosGenericos();
  const productosFinal = useFilteredProducts(productos, productosGenericos);

  const handleClickTab = (tabIndex: any) => {
    setActiveTab(tabIndex);
  };
  const renderSeavert = () => {
    return (
      <div className={styles.dataContainer}>
        <div className={styles.data}>
          <div className={styles.topheader}>
            <Image src={"/static/images/seavert.png"} alt="" />

            <Text as="h3">{t("TecnologiasManvertWidget.seavert.name")}</Text>
          </div>
          <div className={styles.info}>
            <TemplateText template="normal">
              {t("TecnologiasManvertWidget.seavert.p1")}
            </TemplateText>
          </div>
        </div>
        <div className={styles.slider}>
          <Image
            src={
              locale === "esp"
                ? "/static/images/gif-seavert-tech-ES.gif"
                : "/static/images/gif-seavert-tech-GB.gif"
            }
            alt="nosotros seavert tech"
          />
        </div>
      </div>
    );
  };

  const renderLignovert = () => {
    return (
      <div className={styles.dataContainer}>
        <div className={styles.data}>
          <div className={styles.topheader}>
            <Image
              src={"/static/images/lignovert.png"}
              alt="nosotros lignovert tech"
            />

            <Text as="h3">{t("TecnologiasManvertWidget.lignovert.name")}</Text>
          </div>
          <div className={styles.info}>
            <TemplateText template="normal">
              {t("TecnologiasManvertWidget.lignovert.p1")}
            </TemplateText>
          </div>
        </div>
        <div className={styles.slider}>
          <Image
            src={
              locale === "esp"
                ? "/static/images/gif-lignovert-tech-ES.gif"
                : "/static/images/gif-lignovert-tech-GB.gif"
            }
            alt="nosotros lignovert technology"
          />
        </div>
      </div>
    );
  };

  const renderNeovert = () => {
    return (
      <div className={styles.dataContainer}>
        <div className={styles.data}>
          <div className={styles.topheader}>
            <Image
              src={"/static/images/neovert.webp"}
              alt="nosotros neovert tech"
            />

            <Text as="h3">{t("TecnologiasManvertWidget.neovert.name")}</Text>
          </div>
          <div className={styles.info}>
            <TemplateText template="normal">
              {t("TecnologiasManvertWidget.neovert.p1")}
            </TemplateText>
          </div>
        </div>
        <div className={styles.slider}>
          <Image
            src={
              i18n.language.substr(0, 2) === "esp"
                ? "/static/images/gif-neovert-tech-ES.gif"
                : "/static/images/gif-neovert-tech-GB.gif"
            }
            alt="nosotros neovert technology"
          />
        </div>
      </div>
    );
  };
  const tabs = [
    {
      key: "seavert",
      icon: "/static/images/seavert.png",
      component: renderSeavert,
    },
    {
      key: "lignovert",
      icon: "/static/images/lignovert.png",
      component: renderLignovert,
    },
    {
      key: "neovert",
      icon: "/static/images/neovert.webp",
      component: renderNeovert,
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.tabswitcher}>
        {tabs.map((item, index) => {
          return (
            <button
              key={item.key}
              onClick={() => handleClickTab(index)}
              type="button"
              className={classNames(styles.tab, {
                [styles.active]: index === activeTab,
              })}
            >
              <Image src={item.icon} alt="" />
              <Text as="h5">
                {t(`TecnologiasManvertWidget.${tabs[index].key}.name`)}
              </Text>
            </button>
          );
        })}
      </div>
      {tabs[activeTab].component()}

      <div className={styles.productsContainer}>
        <Text as="h3" className={styles.title}>
          {t(`TecnologiasManvertWidget.${tabs[activeTab].key}.heading`)}
        </Text>
        <div className={styles.productsList}>
          {productosFinal &&
            productosFinal
              .filter((producto) => {
                switch (tabs[activeTab].key) {
                  case "neovert":
                    return (
                      settings.PRODUCTOS_NEOVERT.indexOf(
                        producto.producto_generico
                      ) >= 0
                    );
                  case "seavert":
                    return (
                      settings.PRODUCTOS_SEAVERT.indexOf(
                        producto.producto_generico
                      ) >= 0
                    );
                  case "lignovert":
                    return (
                      settings.PRODUCTOS_LIGNOVERT.indexOf(
                        producto.producto_generico
                      ) >= 0
                    );
                  default:
                    return false;
                }
              })
              .map((item) => {
                return (
                  <div key={item.id} className={styles.productWrapper}>
                    <ProductCard
                      internacional={item.internacional}
                      id={item.id}
                      card
                      slug={item.slug}
                      name={item.nombre}
                      eco={item.ecologico}
                      category={
                        familiesData.find((x) => x.id === item.familia)?.nombre
                      }
                      categoryColor={
                        familiesData.find((x) => x.id === item.familia)?.color
                      }
                      image={item.imagen_catalogo}
                      descripcion={[item.descripcion2]}
                      animated
                      country={country}
                      margin={5}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};
