import { Multimedia } from "@models/multimedia";
import React from "react";
import { MultimediaThumbnail } from "..";
import styles from "./multimedia-grid.module.scss";

interface MultimediaGridProps {
  multimediaData: Array<Multimedia>;
}

export const MultimediaGrid = (props: MultimediaGridProps): JSX.Element => {
  const { multimediaData } = props;

  return (
    <div className={styles.root}>
      {multimediaData &&
        multimediaData.map((item) => {
          return (
            <MultimediaThumbnail
              key={item.id}
              titulo={item.titulo}
              fecha={item.fecha}
              link={item.link}
              imagen={item.imagen.url}
            />
          );
        })}
    </div>
  );
};
