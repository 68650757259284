import React from "react";
import {
  Navbar,
  CroptologyLink,
  Footer,
} from "src/presentation/components/organisms";
import styles from "./main-layout.module.scss";
import cx from "classnames";
import { BreadcrumbItem } from "@interfaces/breadcrumb-item";
import Cookies from "js-cookie";

interface MainLayoutProps {
  children: any;
  breadcrumb?: BreadcrumbItem[];
}

export const MainLayout = (props: MainLayoutProps): JSX.Element => {
  const { children, breadcrumb = [] } = props;

  return (
    <div className={cx(styles.root_layout)}>
      <Navbar breadcrumb={breadcrumb}></Navbar>
      <main className={cx(styles.content)}>{children}</main>
      <CroptologyLink />
      <Footer />
    </div>
  );
};
