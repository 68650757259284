import React from "react";
import { Image, Text } from "../../atoms";
import styles from "./logo-thumbnail.module.scss";

interface LogoThumbnailProps {
  filename: string;
  titulo: string;
  logo: string;
}

export const LogoThumbnail = (props: LogoThumbnailProps) => {
  const { titulo, logo, filename } = props;
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      download={filename}
      href={logo}
      className={styles.root}
    >
      <div className={styles.imageWrapper}>
        <Image src={logo} alt={"biovert" + titulo} />
      </div>
      <Text as="h4">{titulo}</Text>
    </a>
  );
};
