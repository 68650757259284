import React from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import RightIcon from "@assets/icons/arrow-right.svg";
import styles from "./product-card.module.scss";
import { Image, TemplateText, Text } from "../../atoms";
import Link from "next/link";
import { MainRouter } from "@config/urls";

interface ProductCardProps {
  id?: number;
  slug?: string;
  eco?: boolean;
  image?: string;
  category?: string;
  categoryColor?: string;
  name?: string;
  descripcion?: string[];
  card?: boolean;
  animated?: boolean;
  link?: boolean;
  margin?: number;
  padding?: number;
  country?: string;
  internacional?: boolean;
}

export const ProductCard = (props: ProductCardProps) => {
  const { t } = useTranslation();
  const {
    image,
    slug,
    category,
    name,
    descripcion = [],
    eco,
    card,
    animated,
    link,
    margin = 0,
    categoryColor,
    padding = 16,
    country,
    internacional,
  } = props;
  return (
    <Link
      legacyBehavior
      href={
        internacional === true
          ? `/${MainRouter["producto-detalle-internacional"].generatePath({
              id: slug,
              country: country,
            })}`
          : `/${MainRouter["producto-detalle"].generatePath({
              id: slug,
              country: country,
            })}`
      }
    >
      <a
        className={classNames(
          styles.root,
          styles.dynamic,
          { [styles.card]: card },
          { [styles.animated]: animated }
        )}
        style={{
          marginLeft: `${margin}px`,
          marginRight: `${margin}px`,
          padding: `${padding}px`,
        }}
      >
        <div className={styles.imagecontainer}>
          <div className={styles.imagewrapper}>
            <Image loading="lazy" src={image} alt={"manvert" + name} />
          </div>
          {eco ? (
            <Image
              src={"/static/images/eco.webp"}
              alt="eco"
              className={styles.eco}
            />
          ) : null}
        </div>

        <div className={styles.data}>
          <TemplateText
            template="micro"
            style={{ color: categoryColor }}
            className={classNames(styles.category)}
          >
            {category}
          </TemplateText>
          <Text as="h3">{name}</Text>
          {descripcion.map((item) => {
            return (
              <TemplateText key={item} template="small">
                {item}
              </TemplateText>
            );
          })}

          {link && (
            <TemplateText template="strong" className={styles.productlink}>
              {t("ProductCard.productlink")} <RightIcon />
            </TemplateText>
          )}
        </div>
      </a>
    </Link>
  );
};
