import colors from "./design-tokens/ds-tokens-colors.module.scss";

export const ColorSet = {
  primary: colors.primary,
  secondary: colors.secondary,
  tertiary: colors.tertiary,
  black: colors.black,
  gray800: colors.black800,
  gray400: colors.gray400,
  gray200: colors.gray200,
  gray100: colors.gray100,
  gray75: colors.gray75,
  gray50: colors.gray50,
  white: colors.white,
  transparent: colors.transparent,
  bioestimulantes: colors.bioestimulantes,
  acondicionadores: colors.acondicionadores,
  lignovert: colors.lignovert,
  correctores: colors.correctores,
  aditivos: colors.aditivos,
  quelatos: colors.quelatos,
  npks: colors.npks,
  protector: colors.protector,
  danger: colors.danger,
};

export type SpacingDimensions = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export type ColorPalette = keyof typeof ColorSet;
