import React from "react";
import cx from "classnames";
import styles from "./button.module.scss";

interface ButtonProps {
  variant: "solid" | "outline" | "flat";
  strong?: boolean;
  color?: "primary" | "secondary" | "tertiary" | "danger";
}

export const Button = (
  props: ButtonProps &
    JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLButtonElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const {
    children,
    color = "primary",
    variant = "solid",
    strong,
    ...rest
  } = props;
  const computedClassname = cx(styles.btn, styles[variant], styles[color], {
    [styles.strong]: strong,
  });
  return (
    <button {...props} className={computedClassname}>
      {children}
    </button>
  );
};
