import React, { CSSProperties, ReactNode } from "react";
import cx from "classnames";
import styles from "./atom-text.module.scss";
import { defaults } from "./defaults";
import { TextNodes, TextSizes, TextWeights } from "./text-settings";
import { ColorPalette, SpacingDimensions } from "@styles/style-types";

interface TextProps {
  children: ReactNode;
  as?: TextNodes;
  size?: TextSizes;
  weight?: TextWeights;
  transform?: "capitalize" | "uppercase" | "lowercase";
  align?: "left" | "center" | "right";
  custom?: boolean;
  color?: ColorPalette;
  className?: string;
  bgColor?: ColorPalette;
  pd?: SpacingDimensions;
  pdHor?: SpacingDimensions;
  pdVer?: SpacingDimensions;
  mgHor?: SpacingDimensions;
  mgVer?: SpacingDimensions;
  style?: CSSProperties;
  truncate?: boolean;
  notSelectable?: boolean;
}

export const Text = (props: TextProps): JSX.Element => {
  const {
    custom = false,
    align = "left",
    as = "p",
    bgColor,
    color = "black",
    size = 14,
    weight,
    className,
    children,
    mgHor,
    mgVer,
    pd,
    style,
    transform,
    pdHor,
    pdVer,
    truncate,
    notSelectable,
  } = props;
  const computedClassname = cx(
    styles[
      `t-weight--${
        custom ? weight || defaults[as].fontWeight : defaults[as].fontWeight
      }`
    ],
    styles[
      `t-size--${
        custom ? size || defaults[as].fontSize : defaults[as].fontSize
      }`
    ],
    notSelectable ? styles["t-not-select"] : undefined,
    styles[`t-align--${align}`],
    color ? `color-${color}` : undefined,
    transform ? styles[`t-transform--${transform}`] : undefined,
    truncate ? styles[`t-truncate`] : undefined,
    bgColor ? `bg-${bgColor}` : undefined,
    pd ? `pd-${pd}` : undefined,
    pdHor ? `pd-horizontal-${pdHor}` : undefined,
    pdVer ? `pd-vertical-${pdVer}` : undefined,
    mgHor ? `mg-horizontal-${mgHor}` : undefined,
    mgVer ? `mg-vertical-${mgVer}` : undefined,
    className
  );

  return React.createElement(
    as,
    { className: computedClassname, style: style },
    children
  );
};
