import React from "react";
import styles from "./solucion-thumbnail.module.scss";

import { useProductos } from "src/context/useProductos";
import { MainRouter } from "@config/urls";
import { useFamilias } from "src/context/useFamilias";
import { Text } from "../../atoms";

interface SolucionThumbnailProps {
  nombre: string;
  productos: Array<number>;
  observaciones: string;
  country: string;
}

export const SolucionThumbnail = (props: SolucionThumbnailProps) => {
  const { nombre, productos, observaciones, country } = props;
  const prods = useProductos();
  const familias = useFamilias();
  return (
    <div className={styles.root}>
      <Text as="h4">{nombre}</Text>
      <div className={styles.combinacion}>
        {prods &&
          productos.map((item, index) => {
            const aux = prods.find((prod) => {
              return prod.id === item;
            });
            const fam = familias.find((fam) => aux?.familia === fam.id);
            return (
              <React.Fragment key={index}>
                {index > 0 && <p className={styles.plus}>+</p>}
                <a
                  style={{ color: fam?.color, cursor: "pointer" }}
                  href={`/${MainRouter["producto-detalle"].generatePath({
                    id: aux?.slug || "",
                    country: country,
                  })}`}
                >
                  {aux?.nombre}
                </a>
              </React.Fragment>
            );
          })}
      </div>
      <p className={styles.observaciones}>{observaciones}</p>
    </div>
  );
};
