import { Producto } from "@models/producto";
import { ProductoGenerico } from "@models/productoGenericos";
import { useState, useEffect } from "react";

/**
 * Custom hook to filter and sort products based on certain criteria.
 *
 * @param {Producto[]} productos - The array of Producto objects.
 * @param {ProductoGenerico[]} productosGenericos - The array of ProductoGenerico objects.
 * @returns {any[]} - The filtered and sorted array of products.
 */
function useFilteredAndSortedProducts(
  productos: Producto[],
  productosGenericos: ProductoGenerico[]
) {
  // State to store the filtered and sorted products
  const [filteredAndSortedProducts, setFilteredAndSortedProducts] = useState<
    any[]
  >([]);

  useEffect(() => {
    // Filter products where 'internacional' is true
    const internationalProducts = productos.filter(
      (product) => product.internacional === true
    );

    // Filter ProductoGenerico objects where their 'id' matches 'producto_generico' of international products
    const matchingGenericProducts = productosGenericos.filter(
      (productoGenerico) =>
        internationalProducts.some(
          (product) => product.producto_generico === productoGenerico.id
        )
    );

    // Filter out international products from the original products list
    const nonInternationalProducts = productos.filter(
      (product) => !internationalProducts.includes(product)
    );

    // Combine the non-international products with matching generic products
    const result = [...nonInternationalProducts, ...matchingGenericProducts];

    // Sort the result by 'prioridad' in descending order
    result.sort((a, b) => b.prioridad - a.prioridad);

    // Update the state with the filtered and sorted products
    setFilteredAndSortedProducts(result);
  }, [productos, productosGenericos]);

  return filteredAndSortedProducts;
}

export default useFilteredAndSortedProducts;
