import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCultivos } from "src/context/useCultivos";
import { useGruposDecultivo } from "src/context/useGruposDeCultivo";
import { useSoluciones } from "src/context/useSoluciones";
import GrupoIcon from "@assets/icons/dropdown-grupo.svg";
import CultivoIcon from "@assets/icons/dropdown-cultivo.svg";
import SolucionIcon from "@assets/icons/dropdown-solucion.svg";
import ArrowDownIcon from "@assets/icons/arrow-down.svg";
import ArrowUpIcon from "@assets/icons/arrow-up.svg";
import SearchIcon from "@assets/icons/search.svg";
import styles from "./soluciones-dropdown.module.scss";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { Image, TemplateText } from "../../atoms";
import Link from "next/link";
import { MainRouter } from "@config/urls";
import { GrupoDeCultivo } from "@models/grupo-de-cultivo";
import { Cultivo } from "@models/cultivos";
import { Solucion } from "@models/solucion";
import { useOutsideClick } from "@hooks/useOutsideClick";
import { AnalitycsService } from "@services/analytics-service";
import { FilterOptions } from "react-markdown/lib/react-markdown";

interface SelectedItems {
  grupo?: GrupoDeCultivo | null;
  cultivo?: Cultivo | null;
  solucion?: Solucion | null;
}

interface Dataset {
  grupos: GrupoDeCultivo[];
  cultivos: Cultivo[];
  soluciones: Solucion[];
}

export const SolucionesDropdown = (props: any) => {
  const { country, search, onChange = () => {}, initialFilters } = props;
  const { t } = useTranslation();
  const ref = useRef(null);
  const cultivos = useCultivos();
  const grupos = useGruposDecultivo();
  const soluciones = useSoluciones();
  const [visible, setVisible] = useState<number | null>(null);
  const [urlSolution, setUrlSolution] = useState(
    MainRouter.soluciones.generatePath()
  );
  const [valuesSelected, setValuesSelected] = useState<SelectedItems>({
    grupo: null,
    cultivo: null,
    solucion: null,
  });
  useEffect(() => {
    if (initialFilters) {
      setValuesSelected(initialFilters);
    }
  }, [initialFilters]);

  const [data, setData] = useState<Dataset>({
    grupos: [],
    cultivos: [],
    soluciones: [],
  });

  useOutsideClick(ref, () => {
    setVisible(null);
  });
  useEffect(() => {
    const url = `/${country}${MainRouter.soluciones.generatePath()}?cultivo=${
      valuesSelected.cultivo?.id || "null"
    }&grupo=${valuesSelected.grupo?.id || "null"}&solucion=${
      valuesSelected.solucion?.id || "null"
    }`;
    setUrlSolution(url);
  }, [valuesSelected, country]);

  useEffect(() => {
    if (!cultivos || !grupos || !soluciones) return;
    setData({
      grupos: grupos,
      cultivos: cultivos,
      soluciones: soluciones,
    });
  }, [cultivos, grupos, soluciones]);

  const toggleDropdown = (item: number | null) => {
    if (visible === item) {
      setVisible(null);
    } else {
      setVisible(item);
    }
  };

  const handleSearchClick = () => {
    const labelTemplate = `Grupo: ${
      valuesSelected.grupo?.nombre || "-"
    } Cultivo: ${valuesSelected.cultivo?.nombre || "-"} Solucion: ${
      valuesSelected.solucion?.nombre || "-"
    }`;
    new AnalitycsService().trackEvent({
      category: "BuscadorSoluciones",
      action: "Buscar",
      label: labelTemplate,
    });
  };
  const generateEventOnChange = (item: SelectedItems) => {
    const labelTemplate = `Grupo: ${item.grupo?.nombre || "-"} Cultivo: ${
      item.cultivo?.nombre || "-"
    } Solucion: ${item.solucion?.nombre || "-"}`;

    new AnalitycsService().trackEvent({
      category: "BuscadorSoluciones",
      action: "OpcionSeleccionada",
      label: labelTemplate,
    });
  };

  const handleValueChange = (prop: any, newValue: any) => {
    switch (prop) {
      case "grupo":
        const val: GrupoDeCultivo = newValue as GrupoDeCultivo;
        let cultivosByGrupo = newValue
          ? cultivos.filter((cultivo) => cultivo.grupo_de_cultivo === val.id)
          : cultivos;

        let solucionesByGrupo = newValue
          ? soluciones.filter((solucion) => {
              return solucion.combinaciones_de_productos.find(
                (combinacion: any) =>
                  combinacion.cultivos.find((cult: any) => cult === newValue.id)
              );
            })
          : soluciones;

        setValuesSelected({ grupo: newValue, cultivo: null, solucion: null });
        setData((prev) => {
          return {
            ...prev,
            cultivos: cultivosByGrupo,
            soluciones: solucionesByGrupo,
          };
        });
        onChange({ grupo: newValue, cultivo: null, solucion: null });
        generateEventOnChange({
          grupo: newValue,
          cultivo: null,
          solucion: null,
        });
        break;
      case "cultivo":
        // Si grupo == null, s'actualitza. Cultivo selected s'actualitza. Solucions es calculen.
        let newgrupo = newValue
          ? grupos.find((x) => x.id === newValue.grupo_de_cultivo)
          : null;

        let solucionesByCultivo = newValue
          ? soluciones.filter((solucion) => {
              return solucion.combinaciones_de_productos.find(
                (combinacion: any) => {
                  return combinacion.cultivos.includes(newValue?.id);
                }
              );
            })
          : soluciones;

        setValuesSelected({
          solucion: null,
          grupo: newgrupo,
          cultivo: newValue,
        });
        setData((prev) => {
          return {
            ...prev,
            soluciones: solucionesByCultivo,
          };
        });
        onChange({
          solucion: null,
          grupo: newgrupo,
          cultivo: newValue,
        });
        generateEventOnChange({
          solucion: null,
          grupo: newgrupo,
          cultivo: newValue,
        });

        break;
      case "solucion":
        onChange({
          grupo: valuesSelected.grupo,
          cultivo: valuesSelected.cultivo,
          solucion: newValue,
        });
        setValuesSelected((prev) => {
          return { ...prev, solucion: newValue };
        });
        generateEventOnChange({
          grupo: valuesSelected.grupo,
          cultivo: valuesSelected.cultivo,
          solucion: newValue,
        });
        break;
    }

    setVisible(null);
    // Do nothing
  };

  return (
    <div ref={ref} className={styles.root}>
      {grupos && (
        <div className={styles.dropdownwrapper}>
          <button
            type="button"
            className={styles.headerWrapper}
            onClick={() => toggleDropdown(0)}
          >
            <GrupoIcon />
            <div className={styles.value}>
              <TemplateText
                template="micro"
                className={classNames(styles.title)}
              >
                {t("DropdownSoluciones.label1")}
              </TemplateText>
              <div className={styles.labelContainer}>
                <TemplateText
                  template="merry"
                  className={classNames(styles.label)}
                >
                  {valuesSelected.grupo === null
                    ? t("DropdownSoluciones.default")
                    : valuesSelected.grupo?.nombre}
                </TemplateText>
                {visible === 0 ? (
                  <ArrowUpIcon className={styles.toggleIcon} />
                ) : (
                  <ArrowDownIcon className={styles.toggleIcon} />
                )}
              </div>
            </div>
          </button>
          <ul
            style={{ display: visible === 0 ? "block" : "none" }}
            className={styles.list}
          >
            <li
              key={"Todos"}
              onClick={() => {
                handleValueChange("grupo", null);
              }}
            >
              <TemplateText template="small">
                {t("DropdownSoluciones.default")}
              </TemplateText>
            </li>
            {data.grupos.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  handleValueChange("grupo", item);
                }}
              >
                <TemplateText template="small">{item.nombre}</TemplateText>
              </li>
            ))}
          </ul>
        </div>
      )}
      {cultivos && (
        <div className={styles.dropdownwrapper}>
          <button
            type="button"
            className={styles.headerWrapper}
            onClick={() => toggleDropdown(1)}
          >
            <CultivoIcon />
            <div className={styles.value}>
              <TemplateText
                template="micro"
                className={classNames(styles.title)}
              >
                {t("DropdownSoluciones.label2")}
              </TemplateText>
              <div className={styles.labelContainer}>
                <TemplateText
                  template="merry"
                  className={classNames(styles.label)}
                >
                  {valuesSelected.cultivo === null
                    ? t("DropdownSoluciones.default")
                    : valuesSelected.cultivo?.nombre}
                </TemplateText>
                {visible === 1 ? (
                  <ArrowUpIcon className={styles.toggleIcon} />
                ) : (
                  <ArrowDownIcon className={styles.toggleIcon} />
                )}
              </div>
            </div>
          </button>
          <ul
            style={{ display: visible === 1 ? "block" : "none" }}
            className={styles.list}
          >
            <li
              key={"Todos"}
              onClick={() => {
                handleValueChange("cultivo", null);
              }}
            >
              <TemplateText template="small">
                {t("DropdownSoluciones.default")}
              </TemplateText>
            </li>
            {data.cultivos.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  handleValueChange("cultivo", item);
                }}
              >
                <Image src={item.icono} alt={item.nombre} />
                <TemplateText template="small">{item.nombre}</TemplateText>
              </li>
            ))}
          </ul>
        </div>
      )}

      {soluciones && (
        <div className={styles.dropdownwrapper}>
          <button
            type="button"
            className={styles.headerWrapper}
            onClick={() => toggleDropdown(2)}
          >
            <SolucionIcon />
            <div className={styles.value}>
              <TemplateText
                template="micro"
                className={classNames(styles.title)}
              >
                {t("DropdownSoluciones.label3")}
              </TemplateText>
              <div className={styles.labelContainer}>
                <TemplateText
                  template="merry"
                  className={classNames(styles.label)}
                >
                  {valuesSelected.solucion === null
                    ? t("DropdownSoluciones.default")
                    : valuesSelected.solucion?.nombre}
                </TemplateText>
                {visible === 2 ? (
                  <ArrowUpIcon className={styles.toggleIcon} />
                ) : (
                  <ArrowDownIcon className={styles.toggleIcon} />
                )}
              </div>
            </div>
          </button>
          <ul
            style={{ display: visible === 2 ? "block" : "none" }}
            className={styles.list}
          >
            <li
              key={"Todos"}
              onClick={() => {
                handleValueChange("solucion", null);
              }}
            >
              <TemplateText template="small">
                {t("DropdownSoluciones.default")}
              </TemplateText>
            </li>
            {data.soluciones
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    handleValueChange("solucion", item);
                  }}
                >
                  <TemplateText template="small">{item.nombre}</TemplateText>
                </li>
              ))}
          </ul>
        </div>
      )}
      {search && (
        <Link legacyBehavior href={urlSolution}>
          <a onClick={handleSearchClick} className={styles.searchbutton}>
            <SearchIcon />
            <TemplateText template="strong">
              {t("DropdownSoluciones.search")}
            </TemplateText>
          </a>
        </Link>
      )}
    </div>
  );
};
