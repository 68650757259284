import { endpoints, settings } from "@config/settings";
import { Familia, FamiliaDTO } from "@models/familias";

import { HttpService } from "./http-service";

interface getFamiliasOpts {
  locale?: string;
  country?: string;
}

export class FamiliasService {
  private http = HttpService;

  async getFamilias(opts: getFamiliasOpts): Promise<Familia[]> {
    const params = {
      lang: opts.locale,
      empresa: settings.BIOVERT_EMPRESA,
      pais: opts.country,
    };
    const url = `${settings.API_BASE_URL}${endpoints.back.familias}`;
    const response = await this.http.get<{ datos: FamiliaDTO[] }>(url, {
      params,
    });

    const results = new Familia().deserializeArray(response.data.datos);
    return results;
  }
}
