import { Serializable } from "@interfaces/serializable";

export interface FamiliaDTO {
  id: number;
  color: string;
  nombre: string;
  descripcion: string;
  prioridad: number;
  imagen: string;
}

export class Familia implements Serializable<Familia> {
  id: number;
  color: string;
  nombre: string;
  descripcion: string;
  prioridad: number;
  imagen: string;
  deserialize(input: FamiliaDTO): Familia {
    this.id = input.id || 0;
    this.color = input.color || "";
    this.nombre = input.nombre || "";
    this.descripcion = input.descripcion || "";
    this.prioridad = input.prioridad || 0;
    this.imagen = input.imagen || "";
    return this;
  }
  deserializeArray(input: FamiliaDTO[]): Familia[] {
    return input.map((input) => new Familia().deserialize(input));
  }
}
