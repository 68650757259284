import React from "react";
import cx from "classnames";
import styles from "./indicator.module.scss";
import { Text } from "../../atoms";
import { ColorSet } from "@styles/style-types";

export const Indicator = (props: any) => {
  const {
    title,
    value,
    description,
    borderColor = ColorSet.primary,
    bgcolor,
    color,
    strongLabel,
    animated,
  } = props;
  return (
    <div
      className={cx(styles.root, { [styles.animated]: animated })}
      style={{ backgroundColor: bgcolor, color }}
    >
      <div
        style={{ backgroundColor: borderColor }}
        className={styles.borderFill}
      />
      <div className={styles.info}>
        {title && (
          <Text style={{ color }} as="h3">
            {title}
          </Text>
        )}
        {value && (
          <Text
            as="h3"
            style={{ color }}
            className={cx((styles.header, "heading"))}
          >
            {value}
          </Text>
        )}
        {description && (
          <Text
            style={{ color }}
            className={(styles.description, strongLabel ? "strong" : undefined)}
          >
            {description}
          </Text>
        )}
      </div>
    </div>
  );
};
