import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import ArrowUpIcon from "@assets/icons/arrow-up.svg";
import ArrowDownIcon from "@assets/icons/arrow-down.svg";
import styles from "./distribuidores-dropdown.module.scss";
import { useTranslation } from "next-i18next";
import { useOutsideClick } from "@hooks/useOutsideClick";
import { TemplateText } from "../../atoms";

export const DistribuidoresDropdown = (props: any) => {
  const ref = useRef(null);
  const { title, values, icon, onChange } = props;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);

  useOutsideClick(ref, () => {
    setVisible(false);
  });
  const toggleDropdown = () => {
    setVisible(!visible);
  };

  const handleValueChange = (newValue: any) => {
    setCurrentValue(newValue);
    setVisible(false);
    onChange(newValue);
  };

  return (
    <div ref={ref} className={styles.root}>
      <button
        type="button"
        className={styles.headerWrapper}
        onClick={() => toggleDropdown()}
      >
        {icon}
        <div className={styles.value}>
          <TemplateText template="micro" className={classNames(styles.title)}>
            {title}
          </TemplateText>
          <TemplateText template="merry" className={classNames(styles.label)}>
            {currentValue === null
              ? t("DistribuidoresDropdown.otros")
              : values[currentValue]}
          </TemplateText>
        </div>
        <div className={styles.toggleIcon}>
          {visible ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </button>
      <ul
        style={{ display: visible ? "block" : "none" }}
        className={styles.list}
      >
        {Object.keys(values).map((item) => (
          <li
            key={item}
            onClick={() => {
              handleValueChange(item);
            }}
          >
            <TemplateText template="small">{values[item]}</TemplateText>
          </li>
        ))}
      </ul>
    </div>
  );
};
