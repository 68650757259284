import React, { CSSProperties } from "react";
import cx from "classnames";
import { ColorPalette } from "@styles/style-types";
import styles from "./template-text.module.scss";

interface TemplateTextProps {
  children: React.ReactNode;
  template: "strong" | "small" | "normal" | "micro" | "merry";
  as?: "p" | "span";
  style?: CSSProperties;
  color?: ColorPalette;
  className?: string;
}

export const TemplateText = (props: TemplateTextProps): JSX.Element => {
  const { children, as = "p", style, template, className } = props;

  const computedClassname = cx(styles[template], className);

  return React.createElement(
    as,
    { className: computedClassname, style: style },
    children
  );
};
