import { Serializable } from "@interfaces/serializable";

export interface GrupoDeCultivoDTO {
  id: number;
  nombre: string;
}

export class GrupoDeCultivo implements Serializable<GrupoDeCultivo> {
  id: number;
  nombre: string;

  deserialize(input: GrupoDeCultivoDTO): GrupoDeCultivo {
    this.id = input.id || 0;
    this.nombre = input.nombre || "";
    return this;
  }

  deserializeArray(input: GrupoDeCultivoDTO[]): GrupoDeCultivo[] {
    return input.map((input) => new GrupoDeCultivo().deserialize(input));
  }
}
