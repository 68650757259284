import { settings } from "@config/settings";
import { GrupoDeCultivo } from "@models/grupo-de-cultivo";
import { useAppContext } from "@pages/_app";
import { GruposCultivoService } from "@services/grupos-cultivo-service";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useGruposDecultivo = () => {
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;

  const [gruposDeCultivo, setGruposDeCultivo] = useState<GrupoDeCultivo[]>(
    new GrupoDeCultivo().deserializeArray(
      ctx.gruposDeCultivoData ? JSON.parse(ctx.gruposDeCultivoData) : []
    )
  );

  useEffect(() => {
    if (ctx.gruposDeCultivoData) return;
    new GruposCultivoService()
      .getGruposCultivo({
        country: ctx.country,
        locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
      })
      .then((response) => {
        setGruposDeCultivo(response);
      });
  }, [ctx.country, ctx.gruposDeCultivoData, locale]);

  return gruposDeCultivo;
};
