import React from "react";
import styles from "./medio-thumbnail.module.scss";
import { Text } from "../../atoms";

interface MedioThumbnailProps {
  titulo: string;
  fecha: string;
  imagen: string;
  link: string;
}

export const MedioThumbnail = (props: MedioThumbnailProps) => {
  const { titulo, link, imagen, fecha } = props;
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div
        style={{ backgroundImage: `url(${imagen})` }}
        className={styles.root}
      >
        <div className={styles.card}>
          <Text as="h4">{titulo}</Text>
          <Text className={styles.date}>{fecha}</Text>
          <Text as="span" className={styles.link}>
            Ver más
          </Text>
        </div>
      </div>
    </a>
  );
};
