import { endpoints, settings } from "@config/settings";
import { GrupoDeCultivo } from "@models/grupo-de-cultivo";
import { HttpService } from "./http-service";

interface getGrupoCultivosOpts {
  locale?: string;
  country?: string;
}

export class GruposCultivoService {
  private http = HttpService;

  async getGruposCultivo(
    opts: getGrupoCultivosOpts
  ): Promise<GrupoDeCultivo[]> {
    const params = {
      lang: opts.locale,
      empresa: settings.BIOVERT_EMPRESA,
      pais: opts.country,
    };
    const url = `${settings.API_BASE_URL}${endpoints.back.gruposDeCultivo}`;
    const response = await this.http.get<{ datos: GrupoDeCultivo[] }>(url, {
      params,
    });

    const results = new GrupoDeCultivo().deserializeArray(response.data.datos);
    return results;
  }
}
