import { settings } from "@config/settings";
import { Cultivo } from "@models/cultivos";
import { Familia } from "@models/familias";
import { useAppContext } from "@pages/_app";
import { FamiliasService } from "@services/familias-service";
import { Logger } from "@services/logger";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useFamilias = () => {
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;
  const [familias, setFamilias] = useState(
    new Familia().deserializeArray(
      ctx.familiasData ? JSON.parse(ctx.familiasData) : []
    )
  );

  useEffect(() => {
    if (ctx.familiasData) return;

    new FamiliasService()
      .getFamilias({
        country: ctx.country,
        locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
      })
      .then((response) => {
        Logger.log("Setting familias, from client side");
        setFamilias(response);
      });
  }, [ctx.country, ctx.familiasData, locale, router.pathname]);

  return familias.sort((a, b) => b.prioridad - a.prioridad);
};
