import { Serializable } from "@interfaces/serializable";

export interface CultivoDTO {
  id: number;
  nombre: string;
  grupo_de_cultivo: number;
  icono: string;
  imagen: string;
}

export class Cultivo implements Serializable<Cultivo> {
  id: number;
  nombre: string;
  grupo_de_cultivo: number;
  icono: string;
  imagen: string;

  deserialize(input: CultivoDTO): Cultivo {
    this.id = input.id || 0;
    this.nombre = input.nombre || "";
    this.grupo_de_cultivo = input.grupo_de_cultivo || 0;
    this.icono = input.icono || "";
    this.imagen = input.imagen || "";
    return this;
  }

  deserializeArray(input: CultivoDTO[]): Cultivo[] {
    return input.map((input) => new Cultivo().deserialize(input));
  }
}
