import { Serializable } from "@interfaces/serializable";

export interface SolucionDTO {
  id: number;
  nombre: string;
  descripcion: string;
  combinaciones_de_productos: [
    Array<{
      id: number;
      observaciones: string;
      productos: Array<number>;
      cultivos: Array<number>;
    }>
  ];
}

export class Solucion implements Serializable<Solucion> {
  id: number;
  nombre: string;
  descripcion: string;
  combinaciones_de_productos: [
    Array<{
      id: number;
      observaciones: string;
      productos: Array<number>;
      cultivos: Array<number>;
    }>
  ];
  deserialize(input: SolucionDTO): Solucion {
    this.id = input.id || 0;
    this.nombre = input.nombre || "";
    this.descripcion = input.descripcion || "";
    this.combinaciones_de_productos = input.combinaciones_de_productos || [];
    return this;
  }

  deserializeArray(input: SolucionDTO[]): Solucion[] {
    return input.map((input) => new Solucion().deserialize(input));
  }
}
