import { settings } from "@config/settings";
import { Solucion } from "@models/solucion";
import { useAppContext } from "@pages/_app";
import { Logger } from "@services/logger";
import { SolucionesService } from "@services/soluciones-service";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useSoluciones = () => {
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;

  const [soluciones, setSoluciones] = useState(
    new Solucion().deserializeArray(
      ctx.solucionesData ? JSON.parse(ctx.solucionesData) : []
    )
  );

  useEffect(() => {
    setSoluciones(
      new Solucion().deserializeArray(
        ctx.solucionesData ? JSON.parse(ctx.solucionesData) : []
      )
    );
  }, [locale]);

  useEffect(() => {
    if (ctx.solucionesData) return;

    new SolucionesService()
      .getSoluciones({
        country: ctx.country,
        locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
      })
      .then((response) => {
        Logger.log("Setting soluciones, from client side");
        setSoluciones(response);
      });
  }, [ctx.country, ctx.solucionesData, locale]);

  return soluciones;
};
