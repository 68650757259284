import { TextNodes, TextSizes, TextWeights } from "./text-settings";

type Defaults = {
  [K in TextNodes]: {
    fontSize?: TextSizes;
    fontWeight?: TextWeights;
  };
};

export const defaults: Defaults = {
  h1: {
    fontSize: 34,
    fontWeight: 700,
  },
  h2: {
    fontSize: 28,
    fontWeight: 500,
  },
  h3: { fontSize: 20, fontWeight: 700 },
  h4: { fontSize: 16, fontWeight: 700 },
  h5: { fontSize: 14, fontWeight: 500 },
  h6: { fontSize: 14, fontWeight: 500 },
  p: { fontSize: 14, fontWeight: 400 },
  span: { fontSize: 12, fontWeight: 400 },
  strong: { fontSize: 14, fontWeight: 700 },
  caption: { fontSize: 10, fontWeight: 400 },
};
