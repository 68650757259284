import React from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";

import RightIcon from "@assets/icons/right-arrow-green.svg";
import styles from "./nosotros-sub-menu.module.scss";
import Link from "next/link";
import { MainRouter } from "@config/urls";
import { Image, TemplateText } from "src/presentation/components/atoms";

export const NosotrosSubMenu = (props: any) => {
  const { visible } = props;
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.root, {
        [styles.show]: visible,
        [styles.hide]: !visible,
      })}
    >
      <div className={styles.banner}>
        <Image
          loading="lazy"
          src={"/static/images/nosotros-menu-manvert-bioestimulantes.webp"}
          alt="nosotros menu manvert bioestimulantes"
        />
      </div>
      <div className={styles.links}>
        <Link
          legacyBehavior
          passHref
          href={MainRouter["quienes-somos"].generatePath()}
        >
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.quienes")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
        <Link legacyBehavior passHref href={MainRouter.equipo.generatePath()}>
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.equipo")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
        <Link
          legacyBehavior
          passHref
          href={MainRouter.innovacion.generatePath()}
        >
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.innovacion")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
        <Link
          legacyBehavior
          passHref
          href={MainRouter["biovert-mundo"].generatePath()}
        >
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.mundo")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
        <Link
          legacyBehavior
          passHref
          href={MainRouter.croptology.generatePath()}
        >
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.croptology")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
        <Link legacyBehavior passHref href={MainRouter.calidad.generatePath()}>
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.calidad")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
        <Link
          legacyBehavior
          passHref
          href={MainRouter.compromiso.generatePath()}
        >
          <a>
            <TemplateText template="normal">
              {t("NosotrosSubMenu.compromiso")}
            </TemplateText>
            <RightIcon />
          </a>
        </Link>
      </div>
    </div>
  );
};
