import { Logo } from "@models/logo";
import React from "react";
import { LogoThumbnail } from "..";
import styles from "./logos-grid.module.scss";

interface LogosGridProps {
  logosData?: Array<Logo>;
}

export const LogosGrid = (props: LogosGridProps) => {
  const { logosData = [] } = props;

  return (
    <div className={styles.root}>
      {logosData.map((item) => {
        return (
          <LogoThumbnail
            key={item.logo.url}
            filename={item.logo.url}
            titulo={item.titulo}
            logo={item.logo.url}
          />
        );
      })}
    </div>
  );
};
