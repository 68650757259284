import React from "react";
import classNames from "classnames";
import styles from "./simple-link.module.scss";
import { TemplateText } from "..";
import Link from "next/link";

export const SimpleLink = (props: any) => {
  const {
    label,
    link,
    strong,
    simbolyc,
    outlined,
    newtab,
    compromiso,
    locale,
  } = props;
  if (simbolyc) {
    return (
      <div
        className={classNames(
          {
            [styles.strong]: strong,
          },
          {
            [styles.compromiso]: compromiso,
          },
          styles.root,
          outlined && styles.outlined
        )}
      >
        {label}
      </div>
    );
  }

  if (compromiso) {
    if (locale === "esp") {
      return (
        <a href={link} target={newtab ? "_blank" : undefined} rel="noreferrer">
          <TemplateText
            className={classNames(
              styles.root,
              strong && styles.strong,
              styles.compromiso,

              outlined && styles.outlined
            )}
            template="normal"
          >
            {label}
          </TemplateText>
        </a>
      );
    } else {
      return (
        <a href={link} target={newtab ? "_blank" : undefined} rel="noreferrer">
          <TemplateText
            className={classNames(
              styles.root,
              strong && styles.strong,
              styles.compromisoEm,
              outlined && styles.outlined
            )}
            template="normal"
          >
            {label}
          </TemplateText>
        </a>
      );
    }
  }
  return (
    <a href={link} target={newtab ? "_blank" : undefined} rel="noreferrer">
      <TemplateText
        className={classNames(
          {
            [styles.strong]: strong,
          },
          {
            [styles.compromiso]: compromiso,
          },

          styles.root,
          outlined && styles.outlined
        )}
        template="small"
      >
        {label}
      </TemplateText>
    </a>
  );
};
