import { BreadcrumbItem } from "@interfaces/breadcrumb-item";
import React from "react";
import styles from "./breadcrumb.module.scss";
import classNames from "classnames";
import { DiagonalDivider } from "../../atoms";
import Link from "next/link";

interface BreadcrumbProps {
  values: BreadcrumbItem[];
}

export const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
  const { values } = props;

  const renderItem = (label: string, link: string, index: number) => {
    return (
      <React.Fragment key={label}>
        {index > 0 ? <DiagonalDivider color={"gray400"} height={18} /> : null}
        {index === values.length - 1 ? (
          <span className={classNames(styles.item, styles.disabled)}>
            {label}
          </span>
        ) : (
          <Link legacyBehavior key={label} href={link}>
            <a
              className={classNames(styles.item, {
                [styles.disabled]: index === values.length - 1,
              })}
            >
              {label}
            </a>
          </Link>
        )}
      </React.Fragment>
    );
  };

  return (
    <div
      className={styles.root}
      style={{ paddingBottom: values.length > 0 ? "12px" : "0px" }}
    >
      {values.map((item, index) => {
        return renderItem(item.label, item.url, index);
      })}
    </div>
  );
};
