/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRouter } from "next/router";
import React, { useState, useRef } from "react";
import cx from "classnames";
import styles from "./language-dropdown.module.scss";
import { useOutsideClick } from "@hooks/useOutsideClick";
import ArrowUp from "@assets/icons/arrow-up.svg";
import ArrowDown from "@assets/icons/arrow-down.svg";
import { TemplateText } from "../../atoms";

export const LanguageDropdown = (): JSX.Element => {
  // Refs
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);
  // State
  const [open, setOpen] = useState<boolean>(false);

  // Hooks
  const router = useRouter();

  const langs = [
    { key: "esp", label: "Español" },
    { key: "en", label: "English" },
  ];

  useOutsideClick(ref, () => {
    setOpen(open);
  });

  const toggleDropdown = () => {
    setVisible(!visible);
  };

  return (
    <div ref={ref} className={styles.root}>
      <button type="button" onClick={() => toggleDropdown()}>
        <div className={styles.value}>
          <TemplateText template="merry">
            {
              (langs.find((item) => item.key === router.locale) || langs[0])
                .label
            }
          </TemplateText>
        </div>

        <div className={styles.toggleIcon}>
          {visible ? <ArrowUp /> : <ArrowDown />}
        </div>
      </button>
      {visible && (
        <ul>
          {langs.map((item) => (
            <li
              key={item.key}
              onClick={() => {
                router.push(router.asPath, router.asPath, {
                  locale: item.key,
                });
                toggleDropdown();
              }}
            >
              <TemplateText template="small">{item.label}</TemplateText>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
