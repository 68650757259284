import { ColorPalette } from "@styles/style-types";
import React from "react";
import styles from "./diagonal-divider.module.scss";
import cx from "classnames";

interface DiagonalDividerProps {
  height?: number;
  color?: ColorPalette;
}

export const DiagonalDivider = (props: DiagonalDividerProps): JSX.Element => {
  const { color = "primary", height = 32 } = props;
  return (
    <div
      className={cx(styles.root, `bg-color-${color}`)}
      style={{ height: `${height}px` }}
    ></div>
  );
};
