import { endpoints, settings } from "@config/settings";
import { Cultivo, CultivoDTO } from "@models/cultivos";
import { HttpService } from "./http-service";

interface getCultivosOpts {
  locale?: string;
  country?: string;
}

export class CultivosService {
  private http = HttpService;

  async getCultivos(opts: getCultivosOpts): Promise<Cultivo[]> {
    const params = {
      lang: opts.locale,
      empresa: settings.BIOVERT_EMPRESA,
      pais: opts.country,
    };
    const url = `${settings.API_BASE_URL}${endpoints.back.cultivos}`;
    const response = await this.http.get<{ datos: CultivoDTO[] }>(url, {
      params,
    });

    const results = new Cultivo().deserializeArray(response.data.datos);
    return results;
  }
}
