import { Serializable } from "@interfaces/serializable";

export interface ProductoDetalleDTO {
  slug: string;
  nombre: string;
  descripcion: string;
  descripcion2: string;
  seo_title: string;
  seo_description: string;
  seo_imagen_catalogo_alt: string;
  id_producto_generico: number;
  imagen_catalogo: string;
  grupos_de_cultivo: Array<string>;
  soluciones: Array<string>;
  ecologico: string;
  ensayos: Array<{
    slug: string;
    pais: string;
    nombre: string;
    imagen: string;
  }>;
  productos_relacionados: Array<{
    slug: string;
    nombre: string;
    descripcion2: string;
    imagen_catalogo: string;
    ecologico?: string;
    familia: {
      nombre: string;
      color: string;
    };
  }>;
}

export class ProductoDetalle implements Serializable<ProductoDetalle> {
  slug: string;
  nombre: string;
  descripcion: string;
  descripcion2: string;
  seo_title: string;
  seo_description: string;
  seo_imagen_catalogo_alt: string;
  id_producto_generico: number;
  imagen_catalogo: string;
  grupos_de_cultivo: Array<string>;
  soluciones: Array<string>;
  ecologico: string;
  ensayos: Array<{
    slug: string;
    pais: string;
    nombre: string;
    imagen: string;
  }>;
  productos_relacionados: Array<{
    slug: string;
    nombre: string;
    descripcion2: string;
    imagen_catalogo: string;
    ecologico?: string;
    familia: {
      nombre: string;
      color: string;
    };
  }>;

  deserialize(input: ProductoDetalleDTO): ProductoDetalle {
    this.slug = input.slug || "";
    this.nombre = input.nombre || "";
    this.ecologico = input.ecologico || "no";
    this.descripcion = input.descripcion || "";
    this.descripcion2 = input.descripcion2 || "";
    this.id_producto_generico = input.id_producto_generico || 0;
    this.imagen_catalogo = input.imagen_catalogo || "";
    this.grupos_de_cultivo = input.grupos_de_cultivo || [];
    this.soluciones = input.soluciones || [];
    this.ensayos = input.ensayos || [];
    this.seo_title = input.seo_title || "";
    this.seo_description = input.seo_description || "";
    this.seo_imagen_catalogo_alt = input.seo_imagen_catalogo_alt || "";
    this.productos_relacionados = input.productos_relacionados
      ? input.productos_relacionados.map((x) => {
          return {
            descripcion2: x.descripcion2,
            slug: x.slug,
            nombre: x.nombre,
            imagen_catalogo: x.imagen_catalogo,
            ecologico: x.ecologico || "no",
            familia: { nombre: x.familia.nombre, color: x.familia.color },
          };
        })
      : [];
    return this;
  }
}
