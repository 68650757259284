import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import ArrowUpIcon from "@assets/icons/arrow-up.svg";
import ArrowDownIcon from "@assets/icons/arrow-down.svg";

import styles from "./familias-dropdown.module.scss";
import { useFamilias } from "src/context/useFamilias";
import { useOutsideClick } from "@hooks/useOutsideClick";
import { TemplateText } from "../../atoms";

export const FamiliasDropdown = (props: any) => {
  const ref = useRef(null);
  const { card, onChange, familia } = props;
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();
  const families = useFamilias();

  const toggleDropdown = () => {
    setVisible(!visible);
  };

  useOutsideClick(ref, () => {
    setVisible(false);
  });

  const handleValueChange = (newValue: any) => {
    setVisible(false);
    onChange(newValue);
  };

  return (
    <div ref={ref} className={classNames(styles.root, { [styles.card]: card })}>
      <button
        type="button"
        className={styles.headerWrapper}
        onClick={() => toggleDropdown()}
      >
        <div className={styles.value}>
          <TemplateText template="merry" className={classNames([styles.label])}>
            {familia === null ? t("FamiliasDropdown.default") : familia.nombre}
          </TemplateText>
        </div>
        <div className={styles.toggleIcon}>
          {visible ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </button>
      <ul
        style={{ display: visible ? "block" : "none" }}
        className={styles.list}
      >
        <li
          key="Todos"
          onClick={() => {
            handleValueChange(null);
          }}
        >
          <TemplateText
            template="small"
            style={{ borderLeft: `3px solid white` }}
          >
            {t("FamiliasDropdown.default")}
          </TemplateText>
        </li>
        {families &&
          families.map((item) => (
            <li
              key={item.nombre}
              onClick={() => {
                handleValueChange(item.nombre);
              }}
            >
              <TemplateText
                template="small"
                style={{ borderLeft: `3px solid ${item.color}` }}
              >
                {item.nombre}
              </TemplateText>
            </li>
          ))}
      </ul>
    </div>
  );
};
