import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { i18n, useTranslation } from "next-i18next";
import ArrowUpIcon from "@assets/icons/arrow-up.svg";
import ArrowDownIcon from "@assets/icons/arrow-down.svg";

import styles from "./paises-dropdown.module.scss";
import { useOutsideClick } from "@hooks/useOutsideClick";
import { TemplateText } from "../../atoms";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import SearchIcon from "@assets/icons/search.svg";
import { settings } from "@config/settings";
import { MainRouter } from "@config/urls";
import { paisesDropdown } from "@utils/PaisesDropdown";

export const PaisesDropdown = (props: any) => {
  const ref = useRef(null);
  const { card, onChange, familia } = props;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  // const paises = usePaises();
  const paises = paisesDropdown;

  const router = useRouter();
  const [datos, setDatos] = useState("");
  const toggleDropdown = () => {
    setVisible(!visible);
  };

  useOutsideClick(ref, () => {
    setVisible(false);
  });
  const handleValueChange = (newValue: any) => {
    setVisible(false);
    const CookieConsent = Cookies.get(settings.CookieConsent);

    if (CookieConsent !== undefined) {
      Cookies.set(settings.countryCookie, newValue.pk.toLowerCase(), {
        expires: 7,
        path: "/",
      });
      onChange(
        i18n?.language === "esp"
          ? newValue.fields.nombre.es_ES
          : newValue.fields.nombre.en_US
      );

      router.replace(MainRouter.home.generatePath());

      setDatos("");
    }
    onChange(
      i18n?.language === "esp"
        ? newValue.fields.nombre.es_ES
        : newValue.fields.nombre.en_US
    );
    setDatos("");
  };
  useEffect(() => {
    if (visible == true) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [visible]);

  return (
    <div ref={ref} className={classNames(styles.root, { [styles.card]: card })}>
      <button
        type="button"
        className={styles.headerWrapper}
        onClick={() => toggleDropdown()}
      >
        <div className={styles.value}>
          <TemplateText template="merry" className={classNames([styles.label])}>
            {familia === null ? t("FamiliasDropdown.default") : familia}
          </TemplateText>
        </div>
        <div className={styles.toggleIcon}>
          {visible ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </button>
      <ul
        style={{ display: visible ? "block" : "none" }}
        className={styles.list}
      >
        <div className={styles.searchContainer}>
          <div className={styles.searchinput}>
            <SearchIcon height={25} />
            <form>
              <input
                type="text"
                id="name"
                name="name"
                onChange={(e) => setDatos(e.target.value)}
                value={datos}
              />
            </form>
          </div>
        </div>
        {paises &&
          paises
            .sort((a, b) => {
              if (i18n?.language === "esp") {
                if (a.fields.nombre.es_ES < b.fields.nombre.es_ES) {
                  return -1;
                } else if (a.fields.nombre.es_ES > b.fields.nombre.es_ES) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.fields.nombre.en_US < b.fields.nombre.en_US) {
                  return -1;
                } else if (a.fields.nombre.en_US > b.fields.nombre.en_US) {
                  return 1;
                } else {
                  return 0;
                }
              }
            })
            .filter((pais) => {
              return i18n?.language === "esp"
                ? pais.fields.nombre.es_ES
                    .toUpperCase()
                    .includes(datos.toUpperCase())
                : pais.fields.nombre.en_US
                    .toUpperCase()
                    .includes(datos.toUpperCase());
            })
            .map((item) => (
              <li
                key={item.pk}
                onClick={() => {
                  handleValueChange(item);
                }}
              >
                <TemplateText template="small">
                  {i18n?.language === "esp"
                    ? item.fields.nombre.es_ES
                    : item.fields.nombre.en_US}
                </TemplateText>
              </li>
            ))}
      </ul>
    </div>
  );
};
