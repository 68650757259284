import React from "react";
import classNames from "classnames";
import styles from "./familia-banner.module.scss";
import { TemplateText, Text } from "../../atoms";

export const FamiliaBanner = (props: any) => {
  const { title, paragraphs, image, card } = props;
  return (
    <div
      className={classNames(styles.root, { [styles.card]: card })}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles.title}>
        <Text as="h1" className="heading">
          {title}
        </Text>
      </div>
      <div className={styles.description}>
        {paragraphs.map((item: any) => {
          return (
            <TemplateText key={item} template="normal">
              {item}
            </TemplateText>
          );
        })}
      </div>
    </div>
  );
};
