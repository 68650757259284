import { settings } from "@config/settings";
import { Producto } from "@models/producto";
import { useAppContext } from "@pages/_app";
import { Logger } from "@services/logger";
import { ProductosService } from "@services/productos-service";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useProductos = () => {
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;
  const { lang } = router.query;
  const [productos, setProductos] = useState(
    new Producto().deserializeArray(
      ctx.productosData ? JSON.parse(ctx.productosData) : []
    )
  );

  useEffect(() => {
    if (ctx.productosData) return setProductos(JSON.parse(ctx.productosData));
    new ProductosService()
      .getProductos({
        country: ctx.country,
        locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
      })
      .then((response) => {
        Logger.log("Setting productos, from client side");
        setProductos(response);
      });
  }, [ctx.country, ctx.productosData, locale, lang]);

  return productos.sort((a, b) => b.prioridad - a.prioridad);
};
