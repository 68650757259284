import { useState, useEffect } from "react";
import { useAppContext } from "@pages/_app";
import { ProductosService } from "@services/productos-service";
import { localesParserDictionary } from "@utils/locales-parser";
import { useRouter } from "next/router";
import { Producto } from "@models/producto";
import { ProductoGenerico } from "@models/productoGenericos";
import { settings } from "@config/settings";
import { Logger } from "@services/logger";
import { Console } from "console";

function useCombinedProducts() {
  // Obtén el contexto de la aplicación y el enrutador de Next.js
  const ctx = useAppContext();
  const router = useRouter();
  const { locale } = router;

  // Definimos tres estados para guardar los productos y sus versiones filtradas y ordenadas
  const [productosGenericos, setProductosGenericos] = useState<
    ProductoGenerico[] | null
  >(null);
  const [productosDestacados, setProductosDestacados] = useState<
    Producto[] | null
  >(null);
  const [productosFinal, setProductosFinal] = useState<
    (ProductoGenerico | Producto)[] | null
  >(null);

  useEffect(() => {
    setProductosDestacados(null);
    setProductosGenericos(null);
  }, [locale]);

  // Primer useEffect para cargar datos
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener productos destacados
        if (ctx.productosDestacadosData) {
          setProductosDestacados(JSON.parse(ctx.productosDestacadosData));
        } else {
          const response = await new ProductosService().getProductosDestacados({
            country: ctx.country,
            locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
          });
          Logger.log("Setting productosDestacados, from client side");
          setProductosDestacados(response);
        }

        // Obtener productos genéricos
        if (ctx.productosGenericos) {
          setProductosGenericos(JSON.parse(ctx.productosGenericos));
        } else {
          const response = await new ProductosService().getProductosGenericos({
            locale: localesParserDictionary[locale || settings.DEFAULT_LANG],
          });
          Logger.log("Setting productosGenericos, from client side");
          setProductosGenericos(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Verificar si tenemos suficientes datos para cargar
    if (ctx.country && locale && !productosDestacados && !productosGenericos) {
      fetchData(); // Llamar a la función fetchData para cargar datos
    }
    setProductosFinal(ctx.filteredAndSortedProducts);
  }, [
    ctx.country,
    ctx.productosDestacadosData,
    ctx.productosGenericos,
    ctx.productosGenericosData,
    ctx.filteredAndSortedProducts,
    locale,
    productosDestacados,
    productosGenericos,
  ]);

  // Segundo useEffect para filtrar y ordenar productos
  useEffect(() => {
    if (productosDestacados && productosGenericos) {
      // Filtrar productos internacionales
      const internationalProducts = productosDestacados.filter(
        (product) => product.internacional === true
      );

      // Filtrar productos genéricos que coinciden con productos internacionales
      const matchingGenericProducts = productosGenericos.filter(
        (productoGenerico) =>
          internationalProducts.some(
            (product) => product.producto_generico === productoGenerico.id
          )
      );

      // Filtrar productos no internacionales
      const nonInternationalProducts = productosDestacados.filter(
        (product) => !internationalProducts.includes(product)
      );

      // Combinar los productos filtrados
      const result = [...nonInternationalProducts, ...matchingGenericProducts];

      // Ordenar los productos por prioridad en orden descendente
      result.sort((a, b) => b.prioridad - a.prioridad);

      // Establecer los productos finales
      setProductosFinal(result);
    }
  }, [productosDestacados, productosGenericos]);

  // Devolver los tres conjuntos de productos
  return { productosDestacados, productosGenericos, productosFinal };
}

export default useCombinedProducts;
