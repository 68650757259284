import { Noticia } from "@models/noticia";
import React from "react";
import { NoticiaThumbnail } from "..";
import styles from "./noticias-grid.module.scss";

interface NoticiasGridProps {
  noticiasData: Array<Noticia>;
}

export const NoticiasGrid = (props: NoticiasGridProps): JSX.Element => {
  const { noticiasData } = props;
  return (
    <div className={styles.root}>
      {noticiasData.map((noticia) => {
        return (
          <NoticiaThumbnail
            key={noticia.slug}
            title={noticia.titulo}
            image={noticia.imagen_cabecera.url}
            date={noticia.fecha}
            slug={noticia.slug}
          />
        );
      })}
    </div>
  );
};
